import React, {useState} from 'react'
import Button from './Button'
import  {AiOutlineMenu, AiOutlineClose} from 'react-icons/ai'


const UtsavHeader = () => {
  let Links = [
    {name: "Home", link:"/"},
    {name: "Gallery", link:"/#gallery"},
    // {name: "About", link:"/"}
  ];
  let [open,setOpen]=useState(false);
  let [isOpen, setIsOpen] = useState(false);

  const handleMenuItemClick = () => {
    setIsOpen(false); // Close the menu
};

  return (
    <div className='shadow-md w-full fixed top-0 left-0 z-50'>
      <div className='md:flex items-center justify-between bg-yellow-250 py-3 md:px-10 px-7 '>
        <div>
          <a href='/'>
              <img className="h-14 max-w-md" src="utsav-assets/logo/lus-logo2.png"
              alt="London Utsav Ganesha"/>
          </a>
        </div>
        {/* Mobile Button */}
        <div onClick={()=>setOpen(!open)} className='text-3xl absolute right-8 top-6 cursor-pointer md:hidden text-red-999'>
          { open ? <AiOutlineClose size={20} /> : <AiOutlineMenu size={20} /> }
        </div>
        <ul className={`md:flex md:items-center md:pb-0 pb-12 absolute md:static bg-yellow-250 md:z-auto z-[-1] left-0 w-full md:w-auto md:pl-0 pl-9 transition-all duration-500 ease-in ${open ? 'top-20':'top-[-490px]'}`}>
          {
          Links.map((link)=>(
            <li key={link.name} className='md:ml-8 text-xl md:my-0 my-3.5'>
              <a href={link.link} className='text-base font-medium text-red-999 hover:text-red-500 duration-500 my-google-font'>{link.name}</a>
            </li>
          ))
        }
        <Button>
          Invitation
        </Button>
        </ul>
      </div>
    </div>
  )
}

export default UtsavHeader
