import React from "react";

function Hero1() {
  return (
    <div
      className="hero min-h-screen"
      style={{
        backgroundImage: "url(utsav-assets/images/DSC03498_12MB.jpg)",
      }}
    >
      <div className="hero-overlay bg-opacity-10"></div>
      <div className="hero-content text-neutral-content text-center">
        <div className="max-w-sm">
          <h1 className="mb-5 text-3xl font-bold text-white my-google-font">
            Unite in Harmony
          </h1>
          {/* <p className="mb-5 text-yellow-250">
      May the wisdom and guidance of Lord Ganesha be with us! Celebrate Vinayaka Chaturthi with our loved ones
      </p> */}
          <a
            href="/registration"
            class="text-red-999 bg-yellow-250 hover:bg-red-500 focus:ring-2 focus:ring-yellow-250 font-medium rounded-lg text-lg px-5 py-2.5 dark:bg-yellow-250 dark:hover:bg-red-500 focus:outline-none dark:focus:ring-yellow-250 my-google-font"
          >
            Register for the event
          </a>

          {/* <button className="btn btn-primary">Register</button> */}
        </div>
      </div>
    </div>
  );
}

export default Hero1;
