import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import IndexSectionHeaders3 from '../components/headers/IndexSectionHeaders3.js';
import IndexSectionPortfolio7 from '../components/portfolio/IndexSectionPortfolio7.js';
import IndexSectionFeatures4 from '../components/features/IndexSectionFeatures4.js';
import IndexSectionContacts9 from '../components/contacts/IndexSectionContacts9.js';
import IndexSectionFooters5 from '../components/footers/UtsavFooter.jsx';
import Headers from '../components/headers/Header.jsx';
import Hero from '../components/headers/Hero.jsx'
import SlideShow from '../components/headers/SlideShow.jsx';
import UstavContacts from '../components/contacts/UstavContacts.jsx';
import UtsavFooter from '../components/footers/UtsavFooter.jsx';


const meta = {
  title: '',
  meta: [],
  link: [],
  style: [],
  script: [],
};

export default function Index() {
  return (
    <React.Fragment>
       <HelmetProvider>
        <Helmet {...meta}></Helmet>
      </HelmetProvider> 
      <Headers />
      <Hero />
      <IndexSectionContacts9 />
      <UtsavFooter />
    </React.Fragment>
  );
}

