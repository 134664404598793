import FormfacadeEmbed from "@formfacade/embed-react";
import React from 'react'

const UtsavRegister = () => {
  return (
    <FormfacadeEmbed

    formFacadeURL="https://formfacade.com/include/115488909018760522066/form/1FAIpQLSct3_J-EmYvyiHEIRMPpLqy6AbEF3SzHauKwEoaUM7tiP1STQ/classic.js/?div=ff-compose"
    
    onSubmitForm={() => console.log('Form submitted')}
    
    />
      );
}

export default UtsavRegister
