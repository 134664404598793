import React from "react";

export default function IndexSectionContacts6() {
  return (
    <React.Fragment>
      <>
        <section className="py-5">
        <p className="mt-8 mb-16 text-4xl font-semibold font-heading my-google-font text-center mx-auto" id="invitation">Vināyaka Chaturthī 2024 Invitation</p>

        <div className="container px-4 mx-auto pt-12">
    <div className="flex flex-wrap items-center -mx-4">
      <div className="w-full md:w-1/2 px-4 mb-6 md:mb-0">
        <h2 className="mt-8 mb-6 lg:mb-12 text-4xl lg:text-4xl font-semibold font-heading my-google-font">Ganesh Gayathri Mantra (Shlokam)</h2>
        <div className="max-w-lg mb-6 lg:mb-12">
          {/* <p className="text-xl text-gray-600">वक्रतुण्ड महाकाय सूर्यकोटि समप्रभ । निर्विघ्नं कुरु मे देव सर्वकार्येषु सर्वदा ॥ </p> */}
          {/* <p className="text-xl text-gray-600">Vakratunda Maha-Kaaya Surya-Kotti Samaprabha Nirvighnam Kuru Me Deva Sarva-Kaaryeshu Sarvadaa ||</p> */}
          <p className="text-xl text-gray-600 my-google-font font-semibold"> Tatpurushaaya Vidmahe | Vakratundaaya Dheemahe | Tanno Danthihi Prachodayaat||</p>
          <p className="text-xl text-gray-400 my-google-font py-1">Meaning: We meditate on that super power, we invoke the single tusked boon giver, Ganesh.</p>
        </div>
        {/* <div className="flex flex-wrap"><a className="inline-block px-6 py-4 mb-3 mr-4 text-sm font-medium leading-normal bg-red-400 hover:bg-red-300 text-white rounded transition duration-200" href="#">Get In Touch</a><a className="inline-block px-6 py-4 mb-3 text-sm font-medium leading-normal hover:text-gray-700 rounded border" href="#">Events</a></div> */}
      </div>
      {/* <img className="relative" src="utsav-assets/images/ganesh-chaturthi.jpg.jpg" alt /> */}
      <div className="relative w-full md:w-1/2 px-4">
        {/* <img className="hidden lg:block lg:absolute top-0 right-0 z-10 lg:mt-28" src="utsav-assets/icons/dots/yellow-dot-right-shield.svg" alt /> */}
        <img className="relative rounded-md border-2" src="utsav-assets/images/invitation-2024.jpeg" alt />
        {/* <img className="hidden lg:block lg:absolute bottom-5 lg:left-0 lg:ml-6 lg:mb-20" src="utsav-assets/icons/dots/blue-dot-left-bars.svg" alt /> */}
      </div>
    </div>
  </div>
          <div className="container px-4 mx-auto">
            <div className="max-w-2xl mx-auto mb-12 lg:mb-20 text-center">
              {/* <span className="text-xl text-blue-500 font-semibold py-12 my-8">
                VINAYAKA CHAVITHI 2024 INVITATION
              </span> */}
              {/* <p className="mt-8 mb-16 text-4xl font-semibold font-heading my-google-font" id="invitation">Vināyaka Chaturthī 2024 Invitation</p> */}

              
              
              {/* <h2 className="mt-8 mb-10 text-3xl lg:text-4xl font-semibold font-heading">Lorem ipsum dolor sit amet consectutar domor at elis</h2>
      <p className="text-xl text-gray-500">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque massa nibh, pulvinar vitae aliquet nec, accumsan aliquet orci.</p> */}

              <div className="relative w-full h-96">
              <p className="my-google-font py-3 my-4">Address Location</p>

                {/* <iframe class="absolute top-0 left-0 w-full h-full"
        src="https://www.google.co.uk/maps/dir//Thomas+Wall+Centre,+52+Benhill+Ave,+Sutton+SM1+4DP/@51.3666813,-0.4793065,11z/data=!4m8!4m7!1m0!1m5!1m1!1s0x4876080b3c915ea9:0x7272e56b3d554c13!2m2!1d-0.1909297!2d51.3666825?entry=ttu"
        frameborder="0" allowfullscreen="" aria-hidden="false" tabindex="0">
    </iframe> */}
                <img
                  className="relative rounded-md border-2 my-4"
                  src="utsav-assets/images/maps.png"
                  alt=""
                />
              </div>
            </div>
            <div className="flex flex-wrap -mx-4">
              <div className="w-full lg:w-1/3 px-8 mb-1 lg:mb-0">
                <div className="py-12 h-full border text-center rounded-xl font-semibold">
                  <h3 className="mb-4 text-2xl font-semibold font-heading my-google-font">
                    Venue Address:
                  </h3>
                  <p className="text-gray-500">The Thomas Wall Centre</p>
                  <p className="text-gray-500">52 Benhill Avenue</p>
                  <p className="text-gray-500">Sutton</p>
                  <p className="text-gray-500">SM1 4DP</p>
                </div>
              </div>
              <div className="w-full lg:w-1/3 px-8 mb-4 lg:mb-0">
                <div className="py-12 h-full border text-center rounded-xl font-semibold">
                  <h3 className="mb-4 text-2xl font-semibold font-heading my-google-font">
                    Contact:
                  </h3>
                  <p className="text-gray-500">londonutsavsamithi@gmail.com</p>
                  <p className="text-gray-500">(OR)</p>
                  <p className="text-gray-500">
                    contact@londonutsavsamithi.org
                  </p>
                  <p className="text-gray-500">07896681923 or 07850083603</p>
                </div>
              </div>
              <div className="w-full lg:w-1/3 px-4 lg:mb-0">
        <div className="py-12 h-full border text-center rounded-xl">
          <h3 className="mb-7 text-2xl font-semibold font-heading my-google-font">Map Link:</h3>
          {/* <div className="flex flex-wrap items-end justify-center">
          
          </div> */}
          <a className="text-sm" target="_blank" href="https://maps.app.goo.gl/kGnrsWehZTbaQ1g88">
          <p className="text-gray-500">https://maps.app.goo.gl/kGnrsWehZTbaQ1g88</p>
        </a>
        </div>
      </div>
            </div>
          </div>
        </section>
      </>
    </React.Fragment>
  );
}
