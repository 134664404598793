"use client";
import React from 'react';
import { Card } from "flowbite-react";

export default function IndexSectionFeatures4() {
    return (
        <React.Fragment>
            <>
                <section className="py-4">
  <div className="container mx-auto px-4">
    <div className="flex flex-wrap -mx-4">
      <div className="w-full md:w-1/2 lg:w-1/3 px-4 mb-12 lg:mb-20">
        <div className="flex">
          {/* <span className="flex-shrink-0 mt-2 mr-6 text-gray-500">
            <svg width={18} height={21} viewBox="0 0 18 21" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fillRule="evenodd" clipRule="evenodd" d="M8.98457 19.606C8.98457 19.606 16.6566 17.283 16.6566 10.879C16.6566 4.474 16.9346 3.974 16.3196 3.358C15.7036 2.742 9.99057 0.75 8.98457 0.75C7.97857 0.75 2.26557 2.742 1.65057 3.358C1.03457 3.974 1.31257 4.474 1.31257 10.879C1.31257 17.283 8.98457 19.606 8.98457 19.606Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
              <path d="M6.38599 9.87463L8.27799 11.7696L12.176 7.86963" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
          </span>
          <div>
            <h3 className="mb-4 text-2xl font-semibold font-heading">Security in saving</h3>
            <p className="text-base text-gray-500">Take care to develop resources continually and integrity them with previous projects. More interesting writings for your customers.</p> */}

<Card
      className="max-w-xl"
      imgAlt="Meaningful alt text for an image that is not purely decorative"
      imgSrc="utsav-assets/images/DSC03419-00.jpg"
    >
      <h5 className="text-2xl font-bold tracking-tight text-gray-900 dark:text-white text-center my-google-font">
        EVENTS
      </h5>
      {/* <p className="font-normal text-gray-700 dark:text-gray-400">
        Here are the biggest enterprise technology acquisitions of 2021 so far, in reverse chronological order.
      </p> */}
            <button className="text-red-999 bg-yellow-250 hover:bg-red-500 focus:ring-4 focus:ring-red-500 md:ml-8 font-bold rounded-full text-sm px-6 py-2 my-google-font">Coming Soon...</button>
    </Card>
          {/* </div> */}
        </div>
      </div>
      <div className="w-full md:w-1/2 lg:w-1/3 px-4 mb-12 lg:mb-20">
        <div className="flex">
          {/* <span className="flex-shrink-0 mt-2 mr-6 text-gray-500">
            <svg width={20} height={20} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fillRule="evenodd" clipRule="evenodd" d="M10 0.750122C15.108 0.750122 19.25 4.89112 19.25 10.0001C19.25 15.1081 15.108 19.2501 10 19.2501C4.891 19.2501 0.75 15.1081 0.75 10.0001C0.75 4.89112 4.891 0.750122 10 0.750122Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
              <path d="M9.99512 6.20422V10.6232" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
              <path d="M9.995 13.7961H10.005" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
            </svg>
          </span>
          <div>
            <h3 className="mb-4 text-2xl font-semibold font-heading">No errors</h3>
            <p className="text-base text-gray-500">Take care to develop resources continually and integrity them with previous projects. More interesting writings for your customers.</p>
          </div> */}
          <Card
      className="max-w-xl"
      imgAlt="Meaningful alt text for an image that is not purely decorative"
      imgSrc="utsav-assets/images/DSC03441-00.jpg"
    >
      <h5 className="text-2xl font-bold tracking-tight text-gray-900 dark:text-white text-center my-google-font">
        VIDEOS
      </h5>
      {/* <p className="font-normal text-gray-700 dark:text-gray-400">
        Here are the biggest enterprise technology acquisitions of 2021 so far, in reverse chronological order.
      </p> */}
            <button className="text-red-999 bg-yellow-250 hover:bg-red-500 focus:ring-4 focus:ring-red-500 md:ml-8 font-bold rounded-full text-sm px-6 py-2 my-goggle-font">Coming Soon...</button>

    </Card>
        </div>
      </div>
      <div className="w-full md:w-1/2 lg:w-1/3 px-4 mb-12 lg:mb-20">
        <div className="flex">
          {/* <span className="flex-shrink-0 mt-2 mr-6 text-gray-500">
            <svg width={18} height={20} viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M13.4235 7.4478V5.3008C13.4235 2.7878 11.3855 0.7498 8.87249 0.7498C6.35949 0.7388 4.31349 2.7668 4.30249 5.2808V5.3008V7.4478" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
              <path fillRule="evenodd" clipRule="evenodd" d="M12.6832 19.2496H5.04224C2.94824 19.2496 1.25024 17.5526 1.25024 15.4576V11.1686C1.25024 9.07359 2.94824 7.37659 5.04224 7.37659H12.6832C14.7772 7.37659 16.4752 9.07359 16.4752 11.1686V15.4576C16.4752 17.5526 14.7772 19.2496 12.6832 19.2496Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
              <path d="M8.86304 12.2028V14.4238" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
          </span>
          <div>
            <h3 className="mb-4 text-2xl font-semibold font-heading">Change of access</h3>
            <p className="text-base text-gray-500">Take care to develop resources continually and integrity them with previous projects. More interesting writings for your customers.</p>
          </div> */}

<Card
      className="max-w-xl"
      imgAlt="Meaningful alt text for an image that is not purely decorative"
      imgSrc="utsav-assets/images/DSC04336-00.jpg"
    >
      <h5 className="text-2xl font-bold tracking-tight text-gray-900 dark:text-white text-center my-google-font">
        INVITATIONS
      </h5>
      {/* <p className="font-normal text-gray-700 dark:text-gray-400">
        Here are the biggest enterprise technology acquisitions of 2021 so far, in reverse chronological order.
      </p> */}
            <button className="text-red-999 bg-yellow-250 hover:bg-red-500 focus:ring-4 focus:ring-red-500 md:ml-8 font-bold rounded-full text-sm px-6 py-2 my-goggle-font">Coming Soon...</button>

    </Card>
        </div>
      </div>
      {/* <div className="w-full md:w-1/2 lg:w-1/3 px-4 mb-6 lg:mb-0">
        <div className="flex">
          <span className="flex-shrink-0 mt-2 mr-6 text-gray-500">
            <svg width={22} height={20} viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M16.9026 6.8512L12.4593 10.4642C11.6198 11.1302 10.4387 11.1302 9.59919 10.4642L5.11841 6.8512" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
              <path fillRule="evenodd" clipRule="evenodd" d="M15.9089 19C18.9502 19.0084 21 16.5095 21 13.4384V6.57001C21 3.49883 18.9502 1 15.9089 1H6.09114C3.04979 1 1 3.49883 1 6.57001V13.4384C1 16.5095 3.04979 19.0084 6.09114 19H15.9089Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
          </span>
          <div>
            <h3 className="mb-4 text-2xl font-semibold font-heading">Weekly email updates</h3>
            <p className="text-base text-gray-500">Take care to develop resources continually and integrity them with previous projects. More interesting writings for your customers.</p>
          </div>
        </div>
      </div>
      <div className="w-full md:w-1/2 lg:w-1/3 px-4 mb-6 lg:mb-0">
        <div className="flex">
          <span className="flex-shrink-0 mt-2 mr-6 text-gray-500">
            <svg width={24} height={20} viewBox="0 0 24 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M22.5 10.8055H1.5" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
              <path d="M20.6299 6.5951V5.0821C20.6299 3.0211 18.9589 1.3501 16.8969 1.3501H15.6919" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
              <path d="M3.37012 6.5951V5.0821C3.37012 3.0211 5.04112 1.3501 7.10312 1.3501H8.33912" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
              <path d="M20.6299 10.8046V14.8786C20.6299 16.9406 18.9589 18.6116 16.8969 18.6116H15.6919" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
              <path d="M3.37012 10.8046V14.8786C3.37012 16.9406 5.04112 18.6116 7.10312 18.6116H8.33912" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
          </span>
          <div>
            <h3 className="mb-4 text-2xl font-semibold font-heading">Frequent authentication</h3>
            <p className="text-base text-gray-500">Take care to develop resources continually and integrity them with previous projects. More interesting writings for your customers.</p>
          </div>
        </div>
      </div> */}
      {/* <div className="w-full md:w-1/2 lg:w-1/3 px-4 mb-6 lg:mb-0">
        <div className="flex">
          <span className="flex-shrink-0 mt-2 mr-6 text-gray-500">
            <svg width={22} height={18} viewBox="0 0 22 18" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M16.8877 7.89673C18.2827 7.70073 19.3567 6.50473 19.3597 5.05573C19.3597 3.62773 18.3187 2.44373 16.9537 2.21973" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
              <path d="M18.7285 11.2502C20.0795 11.4522 21.0225 11.9252 21.0225 12.9002C21.0225 13.5712 20.5785 14.0072 19.8605 14.2812" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
              <path fillRule="evenodd" clipRule="evenodd" d="M10.8867 11.6638C7.67273 11.6638 4.92773 12.1508 4.92773 14.0958C4.92773 16.0398 7.65573 16.5408 10.8867 16.5408C14.1007 16.5408 16.8447 16.0588 16.8447 14.1128C16.8447 12.1668 14.1177 11.6638 10.8867 11.6638Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
              <path fillRule="evenodd" clipRule="evenodd" d="M10.8869 8.88788C12.9959 8.88788 14.7059 7.17888 14.7059 5.06888C14.7059 2.95988 12.9959 1.24988 10.8869 1.24988C8.7779 1.24988 7.0679 2.95988 7.0679 5.06888C7.0599 7.17088 8.7569 8.88088 10.8589 8.88788H10.8869Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
              <path d="M4.88484 7.89673C3.48884 7.70073 2.41584 6.50473 2.41284 5.05573C2.41284 3.62773 3.45384 2.44373 4.81884 2.21973" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
              <path d="M3.044 11.2502C1.693 11.4522 0.75 11.9252 0.75 12.9002C0.75 13.5712 1.194 14.0072 1.912 14.2812" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
          </span>
          <div>
            <h3 className="mb-4 text-2xl font-semibold font-heading">List of regular users</h3>
            <p className="text-base text-gray-500">Take care to develop resources continually and integrity them with previous projects. More interesting writings for your customers.</p>
          </div>
        </div>
      </div> */}
    </div>
  </div>
</section>


            </>
        </React.Fragment>
    );
}

