import React from 'react';
// import HeaderSlideShow from './headerSlideShow';

export default function IndexSectionHeaders3() {
    return (
        <React.Fragment>
            <>
                <section className="pb-20">
  {/* <nav className="flex justify-between items-center py-8 px-4 xl:px-10 bg-red-50">
    <a className="text-lg font-semibold" href="#">
      <img className="h-10" src="utsav-assets/logo/lus-circle-logo.png" alt width="auto" />
    </a>
    <div className="lg:hidden">
      <button className="flex items-center p-3 hover:bg-gray-50 rounded">
        <svg className="block h-4 w-4" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
          <title>Mobile menu</title>
          <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
        </svg>
      </button>
    </div>
    <ul className="hidden lg:flex lg:ml-auto lg:mr-12 lg:items-center lg:w-auto lg:space-x-12">
      <li><a className="text-sm font-medium" href="#">Home</a></li>
      <li><a className="text-sm font-medium" href="#">About Us</a></li>
      <li><a className="text-sm font-medium" href="#">Gallery</a></li>
      <li><a className="text-sm font-medium" href="#">Donations</a></li>
    </ul>
    <div className="hidden lg:block"><a className="inline-block py-3 px-8 text-sm leading-normal font-medium bg-red-50 hover:bg-red-100 text-red-500 rounded transition duration-200" href="#">Contact Us</a></div>
  </nav> */}
  {/* <div>
  <HeaderSlideShow />
  </div> */}
  <div className="container px-4 mx-auto pt-12">
    <div className="flex flex-wrap items-center -mx-4">
      <div className="w-full md:w-1/2 px-4 mb-6 md:mb-0">
        <h2 className="mt-8 mb-6 lg:mb-12 text-4xl lg:text-4xl font-semibold font-heading my-google-font">Vakratunda Ganesh Mantram (Sanskrit)</h2>
        <div className="max-w-lg mb-6 lg:mb-12">
          <p className="text-xl text-gray-600">वक्रतुण्ड महाकाय सूर्यकोटि समप्रभ । निर्विघ्नं कुरु मे देव सर्वकार्येषु सर्वदा ॥ </p>
          {/* <p className="text-xl text-gray-600">Vakratunda Maha-Kaaya Surya-Kotti Samaprabha Nirvighnam Kuru Me Deva Sarva-Kaaryeshu Sarvadaa ||</p> */}
          <p className="text-xl text-gray-600 my-google-font">Vakra-Tunndda Maha-Kaaya Suurya-Kotti Samaprabha | Nirvighnam Kuru Me Deva Sarva-Kaaryessu Sarvadaa ||</p>
          {/* <p className="text-xl text-gray-600">Nirvighnam Kuru Me Deva Sarva-Kaaryessu Sarvadaa ||</p> */}
        </div>
        {/* <div className="flex flex-wrap"><a className="inline-block px-6 py-4 mb-3 mr-4 text-sm font-medium leading-normal bg-red-400 hover:bg-red-300 text-white rounded transition duration-200" href="#">Get In Touch</a><a className="inline-block px-6 py-4 mb-3 text-sm font-medium leading-normal hover:text-gray-700 rounded border" href="#">Events</a></div> */}
      </div>
      {/* <img className="relative" src="utsav-assets/images/ganesh-chaturthi.jpg.jpg" alt /> */}
      <div className="relative w-full md:w-1/2 px-4">
        <img className="hidden lg:block lg:absolute top-0 right-0 z-10 lg:mt-28" src="utsav-assets/icons/dots/yellow-dot-right-shield.svg" alt />
        <img className="relative rounded-md border-2" src="utsav-assets/images/ganesh-chaturthi.jpg" alt />
        <img className="hidden lg:block lg:absolute bottom-0 lg:left-0 lg:ml-6 lg:mb-20" src="utsav-assets/icons/dots/blue-dot-left-bars.svg" alt />
      </div>
    </div>
  </div>
  {/* <div className="hidden fixed top-0 left-0 bottom-0 w-5/6 max-w-sm z-50">
    <div className="fixed inset-0 bg-gray-800 opacity-25" />
    <nav className="relative flex flex-col py-6 px-6 w-full h-full bg-white border-r overflow-y-auto">
      <div className="flex items-center mb-8">
        <a className="mr-auto text-lg font-semibold leading-none" href="#">
          <img className="h-7" src="utsav-assets/logo/logo-zeus-red.svg" alt width="auto" />
        </a>
        <button>
          <svg className="h-6 w-6 text-gray-500 cursor-pointer hover:text-gray-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
          </svg>
        </button>
      </div>
      <div>
        <ul>
          <li className="mb-1"><a className="block p-4 text-sm font-medium text-gray-900 hover:bg-gray-50 rounded" href="#">About</a></li>
          <li className="mb-1"><a className="block p-4 text-sm font-medium text-gray-900 hover:bg-gray-50 rounded" href="#">Company</a></li>
          <li className="mb-1"><a className="block p-4 text-sm font-medium text-gray-900 hover:bg-gray-50 rounded" href="#">Services</a></li>
          <li className="mb-1"><a className="block p-4 text-sm font-medium text-gray-900 hover:bg-gray-50 rounded" href="#">Testimonials</a></li>
        </ul>
      </div>
      <div className="mt-auto">
        <div className="pt-6"><a className="block py-3 text-center text-sm leading-normal bg-red-50 hover:bg-red-100 text-red-300 font-semibold rounded transition duration-200" href="/contacts">Contact Us</a></div>
        <p className="mt-6 mb-4 text-sm text-center text-gray-500">
          <span>© 2021 All rights reserved.</span>
        </p>
      </div>
    </nav>
  </div> */}
</section>


            </>
        </React.Fragment>
    );
}

