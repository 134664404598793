import React from 'react'
import { Helmet, HelmetProvider } from 'react-helmet-async';
import UtsavRegister from '../components/register/UtsavRegister'
import Headers from '../components/headers/Header.jsx';
import IndexSectionFooters5 from '../components/footers/UtsavFooter.jsx';
import UtsavHeader from '../components/headers/UtsavHeader.jsx';

const meta = {
    title: '',
    meta: [],
    link: [],
    style: [],
    script: [],
  };


const Registration = () => {
  return (
    <React.Fragment>
       <HelmetProvider>
        <Helmet {...meta}></Helmet>
      </HelmetProvider> 
      <UtsavHeader />
      <UtsavRegister />
      {/* <Headers />
      <Hero />
      <IndexSectionContacts9 /> */}
      <IndexSectionFooters5 />
    </React.Fragment>
  )
}

export default Registration
