import React from 'react'
import Header from './Header'

function Hero() {
  return (
    <div className="hero min-h-screen"
  style={{
    backgroundImage: "url(ustav-assets/images/ganesh-hero.jpg) blue",
  }}>
  <div className="hero-overlay bg-opacity-100"></div>
  <div className="hero-content text-neutral-content text-center">
    <div className="max-w-md">
      <h1 className="mb-5 text-3xl font-bold text-yellow-200">Celebrate Festivals Together</h1>
      <p className="mb-5 text-yellow-200">
      May the wisdom and guidance of Lord Vigneshwara be with us! Celebrate Vinayaka Chaturthi with our loved ones
      </p>
      {/* <button className="btn btn-primary">Get Started</button> */}
    </div>
  </div>
</div>
  )
}

export default Hero


