import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import IndexSectionHeaders3 from '../components/headers/IndexSectionHeaders3';
import IndexSectionPortfolio7 from '../components/portfolio/IndexSectionPortfolio7';
import IndexSectionFeatures4 from '../components/features/IndexSectionFeatures4';
import IndexSectionContacts6 from '../components/contacts/IndexSectionContacts6';
import IndexSectionFooters5 from '../components/footers/UtsavFooter';
import Headers1 from '../components/headers/Header1';
import SlideShow from '../components/headers/SlideShow';
import Hero1 from '../components/headers/Hero1';
// import Hero2 from '../components/headers/Hero2';
import UtsavCards from '../components/features/UtsavCards';
import UtsavAlert from '../components/headers/UtsavAlert';
import UtsavHeaders from '../components/headers/UtsavHeader';
import UtsavFooter from '../components/footers/UtsavFooter';

const meta = {
  title: '',
  meta: [],
  link: [],
  style: [],
  script: [],
};

export default function Index() {
  return (
    <React.Fragment>
       <HelmetProvider>
        <Helmet {...meta}></Helmet>
      </HelmetProvider>
      {/* <UtsavAlert /> */}
      <UtsavHeaders />
      <Hero1 />
      {/* <SlideShow /> */}
      <IndexSectionHeaders3 />
      <IndexSectionPortfolio7 />
      {/* <UtsavCards /> */}
      <IndexSectionFeatures4 />
      <IndexSectionContacts6 />
      <UtsavFooter />
    </React.Fragment>
  );
}

