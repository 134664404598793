import React from 'react';

export default function IndexSectionPortfolio7() {
    return (
        <React.Fragment>
            <>
                <section className="relative py-4" id="gallery">
  <img className="hidden lg:block absolute top-0 left-0 mt-10" src="utsav-assets/icons/dots/blue-dot-left-bars.svg" alt />
  <img className="hidden lg:block absolute bottom-0 right-0 mb-10" src="utsav-assets/icons/dots/yellow-dot-right-shield.svg" alt />
  <div className="container mx-auto px-4">
    <div className="max-w-xl mx-auto mb-12 lg:mb-16 text-center">
      {/* <span className="text-xs text-blue-400 font-semibold">What's new at Shuffle</span> */}
      <h2 className="mt-8 mb-16 text-4xl font-semibold font-heading my-google-font" id="gallery">Vināyaka Chaturthī Festival Gallery</h2>
      {/* <div className="flex">
        <div className="w-auto mx-auto py-1 px-4 bg-gray-100 rounded-md">
          <button className="px-4 py-3 mb-2 lg:mb-0 mr-4 text-sm font-semibold text-gray-900 bg-white rounded-md shadow">Events</button>
          <button className="px-4 py-3 mb-2 lg:mb-0 mr-4 text-sm font-semibold text-gray-500 hover:text-gray-900 rounded-md">Videos</button>
          <button className="px-4 py-3 mb-2 lg:mb-0 mr-4 text-sm font-semibold text-gray-500 hover:text-gray-900 rounded-md">Invitations</button>
          <button className="px-4 py-3 text-sm font-semibold text-gray-500 hover:text-gray-900 rounded-md">Competitions</button>
        </div>
      </div> */}
    </div>
    {/* <div className="text-center"><a className="px-8 py-4 text-sm text-white font-medium leading-normal bg-red-400 hover:bg-red-300 rounded transition duration-200" href="#">See all images</a></div> */}
  </div>
</section>


            </>
        </React.Fragment>
    );
}

