import React from 'react'
import { Helmet, HelmetProvider } from 'react-helmet-async';
import UtsavRegistrationNew from '../components/register/UtsavRegisteration'
import Headers from '../components/headers/Header.jsx';
import IndexSectionFooters5 from '../components/footers/UtsavFooter.jsx';
import UtsavHeader from '../components/headers/UtsavHeader.jsx';
import UtsavFooter from '../components/footers/UtsavFooter.jsx';


const meta = {
  title: '',
  meta: [],
  link: [],
  style: [],
  script: [],
};


const UtsavRegistration = () => {
  return (
<React.Fragment>
       <HelmetProvider>
        <Helmet {...meta}></Helmet>
      </HelmetProvider> 
      <h1 HELLO />
      <UtsavHeader />
      <UtsavRegistrationNew />
      {/* <Headers />
      <Hero />
      <IndexSectionContacts9 /> */}
      <UtsavFooter />
    </React.Fragment>
  )
}

export default UtsavRegistration
