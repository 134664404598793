import React from 'react';
import { TfiYoutube } from "react-icons/tfi";
import { SiInstagram } from "react-icons/si";
import { FaFacebookSquare } from "react-icons/fa";

export default function UtsavFooter() {
    return (
        <React.Fragment>
            <>
                <section className="">
  <div className="container mx-auto px-4 py-6 mb-6 md:mb-20 bg-yellow-250 items-center justify-items-center">
    <div className="flex flex-wrap items-center justify-items-center">
      <div className="w-full lg:w-1/3 px-4 mb-6 lg:mb-0">
      <p className=" text-sm text-red-999 font-semibold py-2 items-center justify-center my-google-font">Social Connect</p>
        <a className="inline-block mr-4 text-gray-900 text-lg font-semibold max-h-20" href="/">
          <img className="h-7" src="utsav-assets/logo/lus-footer-logo.png" />
        </a>
        <a className="inline-block w-10 mr-4" href="">
      <FaFacebookSquare />

        </a>
        <a className="inline-block w-10 mr-4" href="">
        <SiInstagram size={20} />
          </a>

          <a className="inline-block w-10 mr-4" href="">
            {/* <svg width={18} height={18} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M5.4 15.8H5.9V15.3V7.2V6.7H5.4H2.7H2.2V7.2V15.3V15.8H2.7H5.4ZM15.3 15.8H15.8V15.3V10.17C15.8 8.18386 14.1361 6.52 12.15 6.52C11.6417 6.52 11.1085 6.68661 10.6472 6.94292C10.5635 6.98941 10.4809 7.03957 10.4 7.09321V6.7H9.9H7.2H6.7V7.2V15.3V15.8H7.2H9.9H10.4V15.3V10.53C10.4 10.0862 10.8062 9.68 11.25 9.68C11.6938 9.68 12.1 10.0862 12.1 10.53V15.3V15.8H12.6H15.3ZM1.8 0.5H16.2C16.9139 0.5 17.5 1.08614 17.5 1.8V16.2C17.5 16.9139 16.9139 17.5 16.2 17.5H1.8C1.08614 17.5 0.5 16.9139 0.5 16.2V1.8C0.5 1.08614 1.08614 0.5 1.8 0.5ZM1.93 4.05C1.93 5.22614 2.87386 6.17 4.05 6.17C5.22614 6.17 6.17 5.22614 6.17 4.05C6.17 2.87386 5.22614 1.93 4.05 1.93C2.87386 1.93 1.93 2.87386 1.93 4.05Z" fill="#838EA4" stroke="#838EA4" />
            </svg> */}

<TfiYoutube size={20} />
</a>
          

          {/* <svg height="200px" width="200px" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 461.001 461.001" xml:space="preserve" fill="#000000"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <g> <path style="fill:#F61C0D;" d="M365.257,67.393H95.744C42.866,67.393,0,110.259,0,163.137v134.728 c0,52.878,42.866,95.744,95.744,95.744h269.513c52.878,0,95.744-42.866,95.744-95.744V163.137 C461.001,110.259,418.135,67.393,365.257,67.393z M300.506,237.056l-126.06,60.123c-3.359,1.602-7.239-0.847-7.239-4.568V168.607 c0-3.774,3.982-6.22,7.348-4.514l126.06,63.881C304.363,229.873,304.298,235.248,300.506,237.056z"></path> </g> </g></svg> */}
          
        {/* <p className="mb-6 max-w-sm text-base text-gray-500">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p> */}
        {/* <div>
          <a className="inline-block w-10 mr-4" href="#">
            <svg width={10} height={18} viewBox="0 0 10 18" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fillRule="evenodd" clipRule="evenodd" d="M6.63469 17.7274V9.76612H9.35806L9.76664 6.66255H6.63469V4.68138C6.63469 3.78311 6.88796 3.17095 8.20273 3.17095L9.87687 3.17027V0.394337C9.58735 0.357434 8.59354 0.272827 7.43684 0.272827C5.02146 0.272827 3.36785 1.71891 3.36785 4.37401V6.66255H0.63623V9.76612H3.36785V17.7274H6.63469Z" fill="#838EA4" />
            </svg>
          </a>
          <a className="inline-block w-10 mr-4" href="#">
            <svg width={19} height={16} viewBox="0 0 19 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fillRule="evenodd" clipRule="evenodd" d="M18.8181 2.14598C18.1356 2.44844 17.4032 2.65356 16.6336 2.74513C17.4194 2.27462 18.0208 1.52831 18.3059 0.641769C17.5689 1.0775 16.7553 1.39389 15.8885 1.56541C15.1943 0.82489 14.2069 0.363647 13.1118 0.363647C11.0108 0.363647 9.30722 2.06719 9.30722 4.16707C9.30722 4.46489 9.34083 4.75577 9.40573 5.03392C6.24434 4.87513 3.44104 3.3605 1.56483 1.05895C1.23686 1.61986 1.05028 2.27344 1.05028 2.9711C1.05028 4.29107 1.72243 5.45574 2.74225 6.13713C2.11877 6.11629 1.53237 5.94477 1.01901 5.65968V5.70719C1.01901 7.5498 2.33086 9.08762 4.07031 9.43762C3.75161 9.52338 3.41555 9.57089 3.06789 9.57089C2.82222 9.57089 2.58464 9.54656 2.3517 9.5002C2.8361 11.0125 4.24067 12.1123 5.90483 12.1424C4.6034 13.1623 2.96243 13.7683 1.1801 13.7683C0.873008 13.7683 0.570523 13.7498 0.272705 13.7162C1.95655 14.7974 3.95561 15.4279 6.10416 15.4279C13.1026 15.4279 16.928 9.63117 16.928 4.60398L16.9153 4.11147C17.6627 3.57835 18.3094 2.90853 18.8181 2.14598Z" fill="#838EA4" />
            </svg>
          </a>
          <a className="inline-block w-10 mr-4" href="#">
            <svg width={18} height={18} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M5.4 15.8H5.9V15.3V7.2V6.7H5.4H2.7H2.2V7.2V15.3V15.8H2.7H5.4ZM15.3 15.8H15.8V15.3V10.17C15.8 8.18386 14.1361 6.52 12.15 6.52C11.6417 6.52 11.1085 6.68661 10.6472 6.94292C10.5635 6.98941 10.4809 7.03957 10.4 7.09321V6.7H9.9H7.2H6.7V7.2V15.3V15.8H7.2H9.9H10.4V15.3V10.53C10.4 10.0862 10.8062 9.68 11.25 9.68C11.6938 9.68 12.1 10.0862 12.1 10.53V15.3V15.8H12.6H15.3ZM1.8 0.5H16.2C16.9139 0.5 17.5 1.08614 17.5 1.8V16.2C17.5 16.9139 16.9139 17.5 16.2 17.5H1.8C1.08614 17.5 0.5 16.9139 0.5 16.2V1.8C0.5 1.08614 1.08614 0.5 1.8 0.5ZM1.93 4.05C1.93 5.22614 2.87386 6.17 4.05 6.17C5.22614 6.17 6.17 5.22614 6.17 4.05C6.17 2.87386 5.22614 1.93 4.05 1.93C2.87386 1.93 1.93 2.87386 1.93 4.05Z" fill="#838EA4" stroke="#838EA4" />
            </svg>
          </a>
        </div> */}

        
      </div>
      {/* <div className="w-full lg:w-2/3 px-4">
        <div className="flex flex-wrap justify-between -mx-4">
          <div className="w-1/2 lg:w-1/4 px-4 mb-8 lg:mb-0">
            <h3 className="mb-6 text-base font-medium">Company</h3>
            <ul className="text-sm">
              <li className="mb-4"><a className="text-gray-500 hover:text-gray-600" href="#">About Us</a></li>
              <li className="mb-4"><a className="text-gray-500 hover:text-gray-600" href="#">Careers</a></li>
              <li className="mb-4"><a className="text-gray-500 hover:text-gray-600" href="#">Press</a></li>
              <li><a className="text-gray-500 hover:text-gray-600" href="#">Blog</a></li>
            </ul>
          </div>
          <div className="w-1/2 lg:w-1/4 px-4 mb-8 lg:mb-0">
            <h3 className="mb-6 text-base font-medium">Pages</h3>
            <ul className="text-sm">
              <li className="mb-4"><a className="text-gray-500 hover:text-gray-600" href="#">Login</a></li>
              <li className="mb-4"><a className="text-gray-500 hover:text-gray-600" href="#">Register</a></li>
              <li className="mb-4"><a className="text-gray-500 hover:text-gray-600" href="#">Add list</a></li>
              <li><a className="text-gray-500 hover:text-gray-600" href="#">Contact</a></li>
            </ul>
          </div>
          <div className="w-1/2 lg:w-1/4 px-4 mb-8 lg:mb-0">
            <h3 className="mb-6 text-base font-medium">Legal</h3>
            <ul className="text-sm">
              <li className="mb-4"><a className="text-gray-500 hover:text-gray-600" href="#">Terms</a></li>
              <li className="mb-4"><a className="text-gray-500 hover:text-gray-600" href="#">About Us</a></li>
              <li className="mb-4"><a className="text-gray-500 hover:text-gray-600" href="#">Team</a></li>
              <li><a className="text-gray-500 hover:text-gray-600" href="#">Privacy</a></li>
            </ul>
          </div>
          <div className="w-1/2 lg:w-1/4 px-4">
            <h3 className="mb-6 text-base font-medium">Resources</h3>
            <ul className="text-sm">
              <li className="mb-4"><a className="text-gray-500 hover:text-gray-600" href="#">Blog</a></li>
              <li className="mb-4"><a className="text-gray-500 hover:text-gray-600" href="#">Service</a></li>
              <li className="mb-4"><a className="text-gray-500 hover:text-gray-600" href="#">Product</a></li>
              <li><a className="text-gray-500 hover:text-gray-600" href="#">Pricing</a></li>
            </ul>
          </div>
        </div>
      </div> */}
    </div>
  </div>
  <p className="text-center text-sm text-gray-500 pt-4 px-4 border-t">All rights reserved © London Utsav Samithi 2024</p>
</section>


            </>
        </React.Fragment>
    );
}

