import React from 'react'

const Button = (props) => {
  return (
    // <button className='bg-red-999 text-white py-2 px-6 rounded md:ml-8 hover:bg-indigo-400 duration-500 font-medium'>
    //   {props.children}
    // </button>
    <a href="#invitation" className="my-google-font text-white bg-red-999 hover:bg-red-500 focus:ring-4 focus:ring-red-500 md:ml-8 font-medium rounded-lg text-sm px-6 py-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800">
         {props.children}
    </a>

  )
}

export default Button



