import React from 'react';
import {
  BrowserRouter,
  Routes,
  Route
} from 'react-router-dom';

import IndexPage from './pages/Index.js';
import ContactsPage from './pages/Contacts.js';
import RegisterPage from './pages/Registration.js'
import RegistrationNewPage from './pages/UtsavRegistration.js'

function App() {
  return (
    <Routes>
      <Route exact path="/" element={<IndexPage />} />
      <Route path="contacts" element={<ContactsPage />} />
      <Route path="register"  element={<RegisterPage />} />
      <Route path="registration" element={<RegistrationNewPage />} />
    </Routes>
  );
}

export default App;
