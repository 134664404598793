import React from 'react';

const UtsavRegistration = () => {
  return (
    <div style={{ width: '100%', height: '100vh' }}>

      <div className="mb-20" />

      <iframe
        src="https://docs.google.com/forms/d/e/1FAIpQLSct3_J-EmYvyiHEIRMPpLqy6AbEF3SzHauKwEoaUM7tiP1STQ/viewform?embedded=true"
        width="100%"
        height="100%" 
        frameborder="0" 
        marginheight="0" 
        marginwidth="0"
        title="Google Form"
      >
        Loading…
      </iframe>
      <div className="mb-20" />
    </div>
  );
};

export default UtsavRegistration;




