import React from "react";
import { Link } from "react-scroll";

function Header() {
  return (
    <div>
      <nav className="flex justify-between items-center py-8 px-4 xl:px-10 bg-yellow-250">
        <a className="text-lg font-semibold" href="/">
          <img
            className="h-14 w-14"
            src="utsav-assets/logo/lus-circle-logo.png"
            alt="ustav ganesh"
            width={100}
          />
        </a>
        <div className="lg:hidden">
          <button className="flex items-center p-3 hover:bg-gray-99 rounded cursor-pointer">
            <svg
              className="block h-4 w-4"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <title>Mobile menu</title>
              <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
            </svg>
          </button>
        </div>
        <ul className="hidden lg:flex lg:ml-auto lg:mr-12 lg:items-center lg:w-auto lg:space-x-12">
          <li>
            <a className="text-base font-medium text-red-999" href="/">
              Home
            </a>
          </li>
          <li>
            <a className="text-base font-medium text-red-999" href="#about-us">
              About Us
            </a>
          </li>
          <li>
            <Link className="text-base font-medium text-red-999" to="#gallery" smooth={true} duration={500}>Gallery</Link>
          </li>
          <li>
            <a className="text-base font-medium text-red-999" href="/donations">
              Donations
            </a>
          </li>
        </ul>
        <div className="hidden lg:block">
          <a
            className="inline-block py-3 px-8 text-sm leading-normal font-medium bg-red-50 hover:bg-red-100 text-red-999 rounded transition duration-200"
            href="/contacts"
          >
            Contact Us
          </a>
        </div>
      </nav>
    </div>
  );
}

export default Header;
